import React, { useState, useEffect, useRef } from "react";
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Avatar from "@mui/material/Avatar";
import AvatarGroup from '@mui/material/AvatarGroup';
import SockJsClient from 'react-stomp';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Flags from 'country-flag-icons/react/3x2';
import Typography from "@mui/material/Typography"; 
import {
	Language,
	Profession
}  from '../index.js';


const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: '32px',
  maxHeight: '80px', 
  width: '150px', 
  position:"absolute",  
  left: "10px",
  justifyContent: "flex-start"
}));

const floor_status = [{finished: true},
						{finished: true},
						{finished: true},
						{proposed: true, defined : true,finished: true},
						{proposed: true, defined : true,finished: true},
					  ];

const nofFloors = 5;



function LeftMenu({project,changeFloor, selectedFloor, showLocations, setSelectedLangRoles,selectedLangRoles}) {


	let floors = project.current.floors.sort(({number:a}, {number:b}) => a-b);
	
 


  const floorButton = (floor,ind) => { 
	  const nbr = floor.number;
	  const top = 750 - (80*ind +230);
	  const variant = selectedFloor !== nbr ? "outlined" : "contained";	  
	  	return <CustomButton onClick={() => changeFloor(nbr)} 
	  						 key = {ind}
	  						 endIcon={<AvatarGroup>
	  						  {floor_status[ind].defined ?<Avatar variant="square" src='images/defined.png' sx={{margin: '8px', height: '30px', width: '30px' } }/>: null}
	  						  {floor_status[ind].proposed ?<Avatar variant="square" src='images/proposed.png' sx={{margin: '8px', height: '30px', width: '30px' } }/>: null}
	  						  {floor_status[ind].work_in_progres ? <Avatar variant="square" src='images/work_in_progres.png' sx={{margin: '8px', height: '30px', width: '30px' } }/>: null}
	  						  {floor_status[ind].finished ?<Avatar variant="square" src='images/finished.png' sx={{margin: '8px', height: '30px', width: '30px' } }/> : null}
	  						 </AvatarGroup>} 
	  						 variant={variant} color="primary" style={{  top: top + "px" }}>{nbr}</CustomButton>
	}
	
		


  	return (
	  <>
     		{(floors)? (floors.map((floor,ind) => (floorButton(floor,ind)))): null}
	  </>				
  	);
}

export default LeftMenu;