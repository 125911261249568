import React, { useState, useEffect, useRef } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Typography from "@mui/material/Typography"; 
import { Select, MenuItem } from '@mui/material';
function Profession(props) {
	   const { selectedLangRoles, setSelectedLangRoles,availableLangRoles,showLocations,allProfessions } = props;	
	   
	   //const [prof, setProf] = useState(availableLangRoles.roles[0]);
	   const [prof, setProf] = useState("DWI"); 
	   
	   const handleChangeRole =  (event) =>  {
			const role = event.target.value;
			showLocations([role],selectedLangRoles.lang);
			setProf(role);
			setSelectedLangRoles({roles: [role], lang:selectedLangRoles.lang});	          
	  	};
	   	  

	 const MenuSelection = (role,ind) => {
		 	const code = role;
			const profession = allProfessions.current.filter(obj => obj.professionCode == code)[0];
			const text = profession[selectedLangRoles.lang]; 
			let RoleImage = null;
			if (code === "DWI") {
				RoleImage = <div><img alt={code} style = {{display: "inline-block",height: "2.5em"}} src={window.location.origin + '/images/drywall.png'}/>&nbsp;{text}</div>;
			} else if (code == "ELE") {
				RoleImage = <div><img alt={code} style = {{display: "inline-block",height: "2.5em"}} src={window.location.origin + '/images/elect_install.png'}/>&nbsp;{text}</div>;
			} else {
				RoleImage = <div><img alt={code} style = {{display: "inline-block",height: "2.5em"}} src={window.location.origin + '/images/paint.png'}/>&nbsp;{text}</div>;
			}
		  	
		  	return <MenuItem key={"role" + ind} value={code}>
          				{RoleImage}
        			</MenuItem>
		}

  	return (
		<Select value={prof} onChange={handleChangeRole}>
			{availableLangRoles.roles? (availableLangRoles.roles.map((role,ind) => (MenuSelection(role,ind)))): null}
		</Select>	
  	);
}

export default Profession;