import React, { useState, useRef, useEffect} from 'react';
import { useTable,useFlexLayout } from 'react-table';
import './table.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from "@mui/material/Avatar";
import InfoIcon from '@mui/icons-material/Info';

function LocationsTable({tabledata,colors,updateCheckedLocations, selectedLangRoles,showProperties }) {
	

	
	const STATUS_IMAGES = {defined: "images/defined.png", 
							proposed: "images/proposed.png", 
							started: "images/work_in_progres.png", 
							finished:"images/finished.png",
							closedspace:"images/nogo.png",
							reservedspace:"images/wip.png"};
	
	let proposed = tabledata.filter(obj => obj.type === "Proposed").map(obj => obj.id.toString());
	
	const [checkedLocations,setCheckedLocations] = useState([...proposed]);
	const [allChecked,setAllChecked] = useState(false);
	const [allCheckedTypes,setAllCheckedTypes] = useState([]);
	
	const selectedData = useRef(tabledata.filter(obj => obj.type === "Proposed" || obj.type === "Defined" || obj.type === "Started"))
	
	useEffect(() => {
  		proposed = tabledata.filter(obj => obj.type === "Proposed" || obj.type === "Finished" ).map(obj => obj.id.toString());
  		setCheckedLocations(proposed);
  		setAllChecked(false);
  		setAllCheckedTypes(['finished']);
  		selectedData.current = tabledata.filter(obj => obj.type === "Proposed" || obj.type === "Defined" || obj.type === "Started");
	}, [tabledata]);
	
	const handleAllClick = (event, isChecked) => {	
		const id = event.target.name;
		let checked =[...allCheckedTypes];
		if (isChecked) {
			checked.push(id); 
			let newData = [];
			if (id === 'finished') {
				newData= tabledata.filter(obj => obj.type === "Finished");
				newData= newData.concat(tabledata.filter(obj => obj.type === "FinishedSpace"));					
			} else if (id === 'closedspace') {
				newData= tabledata.filter(obj => obj.type === "ClosedSpace");	
			} else {
				newData= tabledata.filter(obj => obj.type === "ReservedSpace");
			}
			let newiIds = checkedLocations;
			newiIds.push(...newData.map(obj => obj.id.toString()));
			setCheckedLocations([...newiIds]);
		} else {
			checked = [...checked.filter(item => item !== id)];
			let hideData = [];
			if (id === 'finished') {
				hideData = tabledata.filter(obj => obj.type === "Finished");	
				hideData= hideData.concat(tabledata.filter(obj => obj.type === "FinishedSpace"));				
			} else if (id === 'closedspace') {
				hideData = tabledata.filter(obj => obj.type === "ClosedSpace");	
			} else {
				hideData = tabledata.filter(obj => obj.type === "ReservedSpace");
			}			
			let hideIds = hideData.map(obj => obj.id.toString());
			let showIds = checkedLocations.filter(id => !hideIds.includes(id));
			setCheckedLocations([...showIds]);
		}
		setAllCheckedTypes([...checked]);
		
	}
	
	const handleClick = (event, isChecked) => {
		console.log("click");
		const id = event.target.name;
		let checked =[...checkedLocations];
		if (isChecked) {
			checked.push(id); 
		} else {
			checked = [...checked.filter(item => item !== id)];
		}	
		setCheckedLocations([...checked]);
	}
 
   const types = ['finished','closedspace','reservedspace'];
   const SelectTypeCheckBox = (type) => {
  	const color = colors[type.toLowerCase()];
  	const img = STATUS_IMAGES[type.toLowerCase()];
	  
	  return <FormControlLabel sx={{backgroundColor: color}}  control={<Checkbox onChange={handleAllClick} 
	  							name={type} 
	  							checked= {allCheckedTypes.includes(type)}/>} 
	  							label={
									  <div style={{ display: 'flex', alignItems: 'center' }}>
          								 <Avatar variant="square" src={img} sx={{margin: '8px', height: '30px', width: '30px' } }/>
        							  </div>} /> 
  }

 
  const LabelCheckBox = (type,id) => {
  	const color = colors[type.toLowerCase()];
  	const img = STATUS_IMAGES[type.toLowerCase()];
  	const infoImg = "images/info.png";
	  
  	return <FormControlLabel sx={{backgroundColor: color}}  
	  							 control={<Checkbox onChange={handleClick} 
	  							name={id.toString()} 
	  							checked= {checkedLocations.includes(id.toString())}/>} 
	  							label={
									  <div style={{ display: 'flex', alignItems: 'center' }}>
          								 <Avatar variant="square" src={img} sx={{margin: '8px', height: '30px', width: '30px' } }/>
        							  </div>} />; 
  }
  
  

  const handleCheckAllClick = (event, isChecked) => {
	  console.log(allChecked);
	  if (!allChecked) {
		const locations = [];
		tabledata.forEach(function(item) {
			locations.push(item.id.toString());		
		})
		setCheckedLocations(locations);
		setAllCheckedTypes(types);
		setAllChecked(true);
	  } else {
		  setCheckedLocations([]);
		  setAllCheckedTypes([]);
		  setAllChecked(false);
	  }	  
  }
  
    const handleInfoClick = (values) => {
		showProperties(values.guids);
  	}
  
  const AllCheckBox = (type,id) => { 
	  	return <FormControlLabel control={<Checkbox onChange={handleCheckAllClick} checked= {allChecked===true}/>} label={"Status"} />; 
  }
  
  
  let location = "Location";
  
  if (selectedLangRoles.lang  === 'fin') {
	  location = "Työkohde";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  location = "Plats";
  }  else if (selectedLangRoles.lang  === 'est') {
	  location = "Töökohad";
  }
  
  
//			  		{cell.row.values.type === 'Started'? <div onClick={() => console.log(cell.row.values)}>
//			  			<Avatar variant="square" src={"images/question.png"} sx={{margin: '8px', height: '30px', width: '30px' } }>		  				
//			  			</Avatar>
//			  		</div> :
//			  		null}
  
  const columns = React.useMemo(
    () => {
		updateCheckedLocations(checkedLocations);
		return [
	{
        Header: ' ',
        accessor: 'id'
      },
      {
        Header: ' ',
        accessor: 'guids'
      },
      {
        Header: AllCheckBox,
        accessor: 'type',
      	Cell: ({ cell }) => (
			  <div style={{display: 'flex', alignItems: 'center'}}>
			  		{LabelCheckBox(cell.row.values.type,cell.row.values.id)}
			  		<div onClick={() => handleInfoClick(cell.row.values)}>
			  		<InfoIcon color="primary" fontSize="large"/>
			  		</div>
			  </div>
      	),
      	maxWidth: 120,
        width: 120,
      },
      {
        Header: location,
        accessor: 'room',
         Cell: ({ cell }) => (
			  <div style={{display: 'flex', alignItems: 'center'}}>
			  		{cell.row.values.room}
			  </div>
		),
        maxWidth: 100,
        width: 100,
      },
      {
        Header: ' ',
        accessor: 'task',
        maxWidth: 250,
        width: 250,
        Cell: ({ cell }) => (
			  <div style={{display: 'flex', alignItems: 'center'}}>
			  		{cell.row.original[selectedLangRoles.lang]}
			  </div>)		
      },
      {
        Header: ' ',
       	Cell: tableProps  => (
           tableProps.row.original.images.map((image) => {
               return <div><img src={'images/' + image}  width={'70px'} height={'70px'} /></div>;
           })
      	)
      },
    ]}, [checkedLocations,allChecked, selectedLangRoles.lang, tabledata]);

//			  			<Avatar variant="square" src={"images/info.png"} sx={{margin: '8px', height: '30px', width: '30px' } }>		  				
//			  			</Avatar>

const tableInstance = useTable({columns, 
								data: [...selectedData.current],  
								initialState: {
     				 				hiddenColumns: ['id','guids']
    							}, 
    						  });

console.log(tableInstance);



const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;



  return (
	  <>
	  
	  	{types.map((type) => (SelectTypeCheckBox(type)))}
        							  							  
		<table {...getTableProps()} className="table">
		      <thead>
		        {headerGroups.map((headerGroup) => (
		          <tr {...headerGroup.getHeaderGroupProps()}>
		            {headerGroup.headers.map((column) => (
		              <th {...column.getHeaderProps({ 
						  								style: { maxWidth: column.maxWidth, 
						  								width: column.width 
						  							},})}>{column.render('Header')}</th>
		            ))}
		          </tr>
		        ))}
		      </thead>
		      <tbody {...getTableBodyProps()}>
		        {rows.map((row) => {
		          prepareRow(row);
		          return (
		            <tr {...row.getRowProps()}>
		              {row.cells.map((cell) => {
		                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
		              })}
		            </tr>
		          );
		        })}
		      </tbody>
		    </table>
	</>	    
  );
}

export default LocationsTable;