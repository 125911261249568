import React, { useState, useRef} from 'react';
import { useTable } from 'react-table';
import './table.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from "@mui/material/Avatar";
import { Box } from '@mui/material';

function InfoTable({message, selectedLangRoles }) {

  let data = [];	
  
  const propertyInfo = [];	
  for (let i = 0; i < message.length; i++) {	
	let row = {};   
	row.name = message[i].name.replace("Basic Wall:", "");
	for (let j = 0; j < message[i].properties.length; j++) {
		let property = message[i].properties[j];
		switch (property.name) {
  			case 'Area':
    			row.area = property.value.toFixed(2);
    			break;
  			case 'Length':
    			row.length = property.value.toFixed(0);
    			break;
    		case 'Structural Material':
    			row.material = property.value;
    			break;
  			default:
    			console.log('Unknown property: ' + property.name);
		}
	 }	  
	 propertyInfo.push(row); 
  }
  data = propertyInfo;
  let name = "Name"
  let area = "Area";
  let length = "Length";
  let material = "Material";
  if (selectedLangRoles.lang  === 'fin') {
	  name = "Nimi";
	  area = "Pinta-ala";
	  length = "Pituus";
	  material = "Materiaali";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name = "Namnet";
	  area = "Yta";
	  length = "Längd";
	  material = "Material";
  } else if (selectedLangRoles.lang  === 'est') {
	  name = "Namnet";
	  area = "Pindala";
	  length = "Pikkus";
	  material = "Materjal";
  }
   
  const columns = React.useMemo(
    () => {
		return [
	  {
        Header: name,
        accessor: 'name'
      },
      {
        Header: area,
        accessor: 'area',
      },
    ]}, [message,selectedLangRoles.lang]);


console.log(columns);
const tableInstance = useTable({ columns, 
								data: data,  
								initialState: {
     				 				hiddenColumns: ['trimbimModelId']
    							} 
    						  });



const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;
const img = "images/QR_code.png"

  return (
	  <div>
	  	<Avatar variant="square" src={img} sx={{margin: '8px', height: '100px', width: '100px' } }/><br/>
		<table {...getTableProps()} className="table">
		      <thead>
		        {headerGroups.map((headerGroup) => (
		          <tr {...headerGroup.getHeaderGroupProps()}>
		            {headerGroup.headers.map((column) => (
		              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
		            ))}
		          </tr>
		        ))}
		      </thead>
		      <tbody {...getTableBodyProps()}>
		        {rows.map((row) => {
		          prepareRow(row);
		          return (
		            <tr {...row.getRowProps()}>
		              {row.cells.map((cell) => {
		                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
		              })}
		            </tr>
		          );
		        })}
		      </tbody>
		    </table>
		 </div>
  );
}

export default InfoTable;