import React, { useState, useEffect, useRef } from "react";
import { AppBar, Toolbar, Button,Typography } from '@mui/material';
import logo from './logo192.png';
import Avatar from "@mui/material/Avatar";
import Help from "./Help";
import {
	Language,
	Profession
}  from '../index.js';


function Header(props) {
	const { changeFloor, selectedFloor, hideWebConnectUI,selectedLangRoles, setSelectedLangRoles,showLocations,getObjectsByProperties} = props;  
	const [availableLangRoles, setAvailableLangRoles] = useState({roles:[], langs:[]});
	const allEmployees = useRef([]);
	const allProfessions = useRef([]);	 
	  
	const url1 =  `/allUsers`;
	const url2 =  `/professions`;
		
	useEffect(() => {
			Promise.all([
	        		fetch(url1),
	        		fetch(url2)     			
	      	])
	      	.then(([response1, response2]) => {
	        	return Promise.all([response1.json(), response2.json()]);
	      	})
	     	 .then(([data1, data2]) => {
				allEmployees.current = data1;
	        	allProfessions.current = data2;
	        	initEmployees();
	      	})     	
        }, []);		  
        
	const initEmployees = () => {
		const roles =[...new Set(allEmployees.current.map(item => item.role))];
		const langs =[...new Set(allEmployees.current.map(item => item.lang))];
		setAvailableLangRoles({roles: roles, langs: langs})
		setSelectedLangRoles({lang: langs[0], roles:[roles[0]]});	
	  }


  return (
    <AppBar position="static">
      <Toolbar style={{ backgroundColor: "#00ccff" }}>
        <Typography style={{ marginRight: 32 }}><img src={logo} alt="Logo" width="192" /> </Typography>    
        <Typography> <Button onClick={() => changeFloor(selectedFloor)} color="primary" startIcon={<Avatar src='images/refresh-button.png' sx={{ height: '50px', width: '50px' }}/>}></Button> </Typography>  
		 <Language selectedLangRoles={selectedLangRoles} setSelectedLangRoles={setSelectedLangRoles} 
		  		   availableLangRoles={availableLangRoles} showLocations={showLocations}></Language>
		 <Profession selectedLangRoles={selectedLangRoles} setSelectedLangRoles={setSelectedLangRoles} 
		  			 availableLangRoles={availableLangRoles} showLocations={showLocations} allProfessions={allProfessions}></Profession> 
      	<div style={{ marginLeft: 'auto' }}>
      		<Help selectedLangRoles={selectedLangRoles}/>
      	</div>
      </Toolbar>
    </AppBar>
  );
}

//       	<Button onClick={() => hideWebConnectUI()} color="primary" sx= {{marginLeft: 'auto'}}>{'X'}</Button> 

export default Header;