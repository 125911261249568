import React, { useState, useEffect,useRef} from 'react';
import { useTable } from 'react-table';
import './table.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from "@mui/material/Avatar";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';

function AnnotationTable({selectedLangRoles, annotations,updateCheckedAnnotations }) {
	const alarms = useRef(annotations.filter(x => x.type === "alarm"));
	const sweAlarmArray =  alarms.current.map(function(a) {return a.swe});
	const engAlarmArray =  alarms.current.map(function(a) {return a.eng});
	const finAlarmArray =  alarms.current.map(function(a) {return a.fin});
	
	const [message, setMessage] = useState('Your server message here.');
	const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  	const [activeAlert, setActiveAlert] = React.useState(false);
  	const [passiveAlert, setPassiveAlert] = React.useState(false);
  	const [checkedAnnotations,setCheckedAnnotations] = React.useState([]);
  	const [allChecked,setAllChecked] = React.useState(false);
  		
 	const setAlarm = (msg) => {
    	setMessage(msg.message ? msg.message : msg);
     	setActiveAlert(true);
    	setOpenAlertDialog(true);
  	}
	
	 const handleAlertDialogClose = (event, reason) => {
	  	if (reason && reason == "backdropClick") {
        	return;
       	}
	  	setOpenAlertDialog(true);
	  	setActiveAlert(false);
	  	let ann = annotations.filter(x => x.type === "alarm");
	  	if (ann !== null && ann.length >0) {
			let id = ann[0].id;
			let checked =[...checkedAnnotations]; 
			checked = [...checked.filter(item => item !== id.toString())];
			setCheckedAnnotations([...checked]);
		}
//	  	setPassiveAlert(true);
  	}
  
//  	const handlePassiveDialogClose = (event, reason) => {
//	  	if (reason && reason == "backdropClick") {
//        	return;
//       	}
//	  	setPassiveAlert(false);
//  	}
	
			
	const TYPE_IMAGES = {info: "images/info.png", 
						warning: "images/reserved.png",
						 alarm: "images/danger.png"};
	
	const handleClick = (event, isChecked) => {
		const id = event.target.name;
		let checked =[...checkedAnnotations];
		if (isChecked) {
			checked.push(id); 
		} else {
			checked = [...checked.filter(item => item !== id.toString())];
		}	

		setCheckedAnnotations([...checked]);
		let ann = annotations.filter(x => x.id === parseInt(id));
		if (ann !== null && ann.length > 0) {
			if (ann[0].type === "alarm") {
				setAlarm(ann[0].engDetail);
			}
		}
		
	}

  
  const LabelCheckBox = (type,id) => {
	const img = TYPE_IMAGES[type.toLowerCase()]; 
	let control = <Checkbox onChange={handleClick} 
	  				name={id} 
	  				checked= {checkedAnnotations.includes(id.toString())}/>;
//	 if (type === 'alarm') {
//		control = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>; 
//	 }				

			return <FormControlLabel  
	  				control={control} 
	  				label={
						<div style={{ display: 'flex', alignItems: 'center' }}>
          					<Avatar variant="square" src={img} sx={{margin: '8px', height: '30px', width: '30px' } }/>
        				</div>} />; 

  }
  
  const checkAll = () => {
	  if (!allChecked) {
		const ids = [];
		annotations.forEach(function(item) {
			ids.push(item.id.toString());
			if (item.type === "alarm") {
				setAlarm(item.engDetail);
			}		
		})
		setCheckedAnnotations(ids);
		setAllChecked(true);
	  } else {
		  setCheckedAnnotations([]);
		  setAllChecked(false);
	  }

	  
	  
  }
  
  const AllCheckBox = () => { 
	  	return <FormControlLabel control={<Checkbox onChange={checkAll} checked= {allChecked===true}/>} label={"Status"} />; 
  }
   
  
  let name = "Name"
  if (selectedLangRoles.lang  === 'fin') {
	  name = "Nimi";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name = "Namnet";
  } else if (selectedLangRoles.lang  === 'est') {
	  name = "Nimi";
  }
  
  
  const columns = React.useMemo(
    () => {
		updateCheckedAnnotations(checkedAnnotations);
		return [
	  {
        Header: ' ',
        accessor: 'id'
      },
      {
        Header: AllCheckBox,
        accessor: 'type',
      	Cell: ({ cell }) => (
			  LabelCheckBox(cell.row.values.type,cell.row.values.id)
      	)
      },
      {
        Header: name,
  		accessor: 'eng',
  		Cell: ({ row }) => (
    		<div>
      			{selectedLangRoles.lang  === 'eng' && row.original.eng}
      			{selectedLangRoles.lang  === 'swe' && row.original.swe}
      			{selectedLangRoles.lang  === 'fin' && row.original.fin}
      			{selectedLangRoles.lang  === 'est' && row.original.est}
    		</div>)
      }
    ]}, [checkedAnnotations,selectedLangRoles.lang]);

//	useEffect(() => {
//  		console.log('Component has rendered or checkedLocations has changed.');
//  		updateCheckedLocations(checkedLocations);
//	}, [checkedLocations]);

console.log(columns);
const tableInstance = useTable({ columns, 
								data: annotations,  
								initialState: {
     				 				hiddenColumns: ['trimbimModelId']
    							} 
    						  });
    						  
//  useEffect(() => {
//    if (alarms.current !== null && alarms.current.length > 0 && selectedLangRoles.lang !== null) {
//		if (selectedLangRoles.lang === "fin") {
//			setAlarm(finAlarmArray.join(" "));
//		} else if (selectedLangRoles.lang === "swe") {
//			setAlarm(sweAlarmArray.join(" "));
//		} else {
//			setAlarm(engAlarmArray.join(" "));
//		}
//	}
//  }, [alarms.current, selectedLangRoles.lang]);




const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
	  <>
		<table {...getTableProps()} className="table">
		      <thead>
		        {headerGroups.map((headerGroup) => (
		          <tr {...headerGroup.getHeaderGroupProps()}>
		            {headerGroup.headers.map((column) => (
		              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
		            ))}
		          </tr>
		        ))}
		      </thead>
		      <tbody {...getTableBodyProps()}>
		        {rows.map((row) => {
		          prepareRow(row);
		          return (
		            <tr {...row.getRowProps()}>
		              {row.cells.map((cell) => {
		                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
		              })}
		            </tr>
		          );
		        })}
		      </tbody>
		    </table>
		    {activeAlert ? (
				<Dialog
        			open={openAlertDialog}
        			onClose={handleAlertDialogClose}
        			aria-labelledby="alert-dialog-title"
        			aria-describedby="alert-dialog-description"
      			>
        			<DialogContent>
          				<DialogContentText id="alert-dialog-description">
          					<Alert onClose={handleAlertDialogClose} variant="filled" severity="error">{message}</Alert>						
          				</DialogContentText>
        		</DialogContent>
      			</Dialog>
			  ) : null			  
			 }			
		</>
  );
}

export default AnnotationTable;