import React, { useState, useEffect} from 'react';
import { useTable } from 'react-table';
import './table.css';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Avatar from "@mui/material/Avatar";
import { Box } from '@mui/material';

function ModelTable({ trimbimModels, asyncShowModels, selectedLangRoles }) {
		
	const [checkedModelIds,setCheckedModelIds] = useState([]);
	const [allChecked,setAllChecked] = useState(false);
	
	const TYPE_IMAGES = {tool: "images/tool.png", 
						 material: "images/material.png"};
	
	const handleClick = (event, isChecked) => {
		const id = event.target.name;
		let checked =[...checkedModelIds];
		if (isChecked) {
			checked.push(id); 
		} else {
			checked = [...checked.filter(item => item !== id)];
		}	
		setCheckedModelIds([...checked]);
	}

  
  const LabelCheckBox = (type,id) => {
	const img = TYPE_IMAGES[type.toLowerCase()];
	  
	  	return <FormControlLabel  
	  							 control={<Checkbox onChange={handleClick} 
	  							name={id} 
	  							checked= {checkedModelIds.includes(id)}/>} 
	  							label={
									  <div style={{ display: 'flex', alignItems: 'center' }}>
          								 <Avatar variant="square" src={img} sx={{margin: '8px', height: '30px', width: '30px' } }/>
        							  </div>} />; 
  }
  
  const checkAll = (event, isChecked) => {
	  if (!allChecked) {
		const modelIds = [];
		trimbimModels.forEach(function(item) {
			modelIds.push(item.trimbimModelId);		
		})
		setCheckedModelIds(modelIds);
		setAllChecked(true);
	  } else {
		  setCheckedModelIds([]);
		  setAllChecked(false);
	  }

	  
	  
  }
  
  const AllCheckBox = (type,id) => { 
	  	return <FormControlLabel control={<Checkbox onChange={checkAll} checked= {allChecked===true}/>} label={"Status"} />; 
  }
   
  
  let name = "Name"
  if (selectedLangRoles.lang  === 'fin') {
	  name = "Nimi";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name = "Namnet";
  }else if (selectedLangRoles.lang  === 'est') {
	  name = "Nimi";
  }
  
  const columns = React.useMemo(
    () => {
		asyncShowModels(checkedModelIds);
		return [
	  {
        Header: ' ',
        accessor: 'trimbimModelId'
      },
      {
        Header: AllCheckBox,
        accessor: 'type',
      	Cell: ({ cell }) => (
			  LabelCheckBox(cell.row.values.type,cell.row.values.trimbimModelId)
      	)
      },
      {
        Header: name,
  		accessor: 'eng',
  		Cell: ({ row }) => (
    		<div>
      			{selectedLangRoles.lang  === 'eng' && row.original.eng}
      			{selectedLangRoles.lang  === 'swe' && row.original.swe}
      			{selectedLangRoles.lang  === 'fin' && row.original.fin}
      			{selectedLangRoles.lang  === 'est' && row.original.est}
    		</div>)
//        accessor: (d) => {
//							let name= d.eng;
//        					if (selectedLangRoles.lang  === 'swe') {name = d.swe;}
//        					else if (selectedLangRoles.lang  === 'fin') {name = d.fin;}
//        					return name;
//        				}
      }
    ]}, [checkedModelIds,trimbimModels,allChecked,selectedLangRoles.lang]);

//	useEffect(() => {
//  		console.log('Component has rendered or checkedLocations has changed.');
//  		updateCheckedLocations(checkedLocations);
//	}, [checkedLocations]);

console.log(columns);
const tableInstance = useTable({ columns, 
								data: trimbimModels,  
								initialState: {
     				 				hiddenColumns: ['trimbimModelId']
    							} 
    						  });



const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
		<table {...getTableProps()} className="table">
		      <thead>
		        {headerGroups.map((headerGroup) => (
		          <tr {...headerGroup.getHeaderGroupProps()}>
		            {headerGroup.headers.map((column) => (
		              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
		            ))}
		          </tr>
		        ))}
		      </thead>
		      <tbody {...getTableBodyProps()}>
		        {rows.map((row) => {
		          prepareRow(row);
		          return (
		            <tr {...row.getRowProps()}>
		              {row.cells.map((cell) => {
		                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
		              })}
		            </tr>
		          );
		        })}
		      </tbody>
		    </table>
  );
}

export default ModelTable;