import React from 'react';
import { useTable } from 'react-table';
import './table.css';
import { Button } from '@mui/material';

function TaskTable({ data }) {
	
	const handleClick = (data) => {
		console.log("click");
		console.log(data);
	}

  
  const columns = React.useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'task',
      	Cell: ({ cell }) => (
        	<Button variant="contained" color="secondary" value={cell.row.values.task} onClick={() => handleClick(cell.row.values.task)}>
          		{cell.row.values.task}
        	</Button>
      	)
      },
      {
        Header: 'Start',
        accessor: 'startTime'
      },
      {
        Header: 'End',
        accessor: 'endTime'
      },
      {
        Header: 'Floor',
        accessor: 'floor'
      },
      {
        Header: 'Room',
        accessor: 'room'
      },
      {
        Header: 'Image',
       	Cell: tableProps  => (
           tableProps.row.original.images.map((image) => {
               return <div><img src={'images/' + image}  height={150} /></div>;
           })
      )
      },
    ], []);

const tableInstance = useTable({ columns, data });



const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = tableInstance;

  return (
<table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TaskTable;