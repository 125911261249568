import React, { useState, useRef } from 'react';
import Avatar from "@mui/material/Avatar";
import Popover from '@mui/material/Popover';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const Help = ({selectedLangRoles }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = (event) => {
	      anchorEl.current = event.currentTarget;
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

const anchorEl = useRef(null);


const helpText =

[{id: 'defined' , texts: {eng: 'Work location is possible',fin: 'Työkohde on mahdollinen',swe: 'Arbetsområde är möjlig',est: 'Töö ulatus on määratletud'}},
{id: 'proposed' , texts:  {eng: 'Proposed work location',fin: 'Ehdotettu työkohde',swe: 'Föreslagen arbetsområde',est: 'Kavandatav töö asukoht'}},
{id: 'started' , texts:  {eng: 'Work started in location',fin: 'Työ aloitettu kohteessa',swe: 'Arbetet påbörjat på områden',est: 'Töö algas asukohas'}},
{id: 'finished' , texts:  {eng: 'Work finished in location',fin: 'Työ valmistunut kohteessa',swe: 'Arbetet slutfördes på områden',est: 'Töö lõpetatud asukohas'}},
{id: 'reservedspace' , texts:  {eng: 'Work location reserved',fin: 'Työkohde varattu',swe: 'Reserverad arbetsområde',est: 'Töö asukoht reserveeritud'}},
{id: 'closedspace' , texts:  {eng: 'Forbidden space',fin: 'Kielletty alue',swe: 'Förbjudet område',est: 'Keelatud ala'}}];


 const getText = (id, language) => {
  const item = helpText.find(item => item.id === id);
  return item ? item.texts[language] : 'No text found';
};
const definedText = getText("defined",selectedLangRoles.lang);
const proposedText = getText("proposed",selectedLangRoles.lang);
const startedText = getText("started",selectedLangRoles.lang);
const finishedText = getText("finished",selectedLangRoles.lang);
const closedspaceText = getText("closedspace",selectedLangRoles.lang);
const reservedspaceText = getText("reservedspace",selectedLangRoles.lang);


  	const STATUS_IMAGES = [{ src: "images/defined.png",text: definedText}, 
							{ src: "images/proposed.png", text: proposedText},
							{ src: "images/work_in_progres.png",text: startedText}, 
							{ src: "images/finished.png", text: finishedText},
							{ src: "images/nogo.png",text: closedspaceText},
							{ src: "images/wip.png",text: reservedspaceText}];
  return (
    <div>
      <Avatar src={'images/file.png'} sx={{ width: 40, height: 40}}onClick={handleClickOpen}/>
        <Popover
        open={open}
        anchorEl={anchorEl.current}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
          <div style={{ textAlign: 'left' }}>
      <IconButton style={{ float: 'left' }} onClick={handleClose}><CloseIcon color="primary" style={{ fontSize: 20 }} sx={{ border: '1px solid black' }}/></IconButton>
        <Table>
          <TableBody>
            {STATUS_IMAGES.map((image, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Avatar src={image.src} sx={{ width: 24, height: 24 }} alt={`Image ${index + 1}`} />
                </TableCell>
                <TableCell>
                  {image.text}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        </div>
      </Popover>
    </div>
  );
};

export default Help;
