
import React, { useState, useEffect , useRef} from "react";
import {
   	Header,
   	Login,
   	RightAccordion,
   	LeftMenu,
   	InfoTable
}  from './modules/index.js';
import { Grid, Button} from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import queryString from 'query-string';
import * as WorkspaceAPI from "trimble-connect-workspace-api";
 
import SockJsClient from 'react-stomp';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';


const CustomButton = styled(Button)(({ theme }) => ({
  fontSize: '32px',
  maxHeight: '80px', 
  minWidth: '80px', 
  position:"absolute",  
  left: "10px"
}));



 
const AUTHORIZATION_ENDPOINT = 'https://id.trimble.com/oauth/authorize';
const SCOPE = 'openid Actor-info-wall';
const COLORS_RGB = {proposed: {r:209, g:255, b:51 }, 
					started:  {r:0, g:176, b:240 },
					defined: {r:255, g:180, b:10}, 
					finished:  {r:0, g:176, b:80 },
					finishedspace:  {r:0, g:176, b:80 },
					object: {r:220, g:20, b:60 }};
					
const COLORS_HEX = {proposed: "#d1ff33", 
					started: "#00b0f0", 
					defined: "#FFB40A", 
					closedspace: "#f0504c", 
					reservedspace: "#ffff7e", 
					finished:"#00b050", 
					finishedspace:"#00b050", 
					object: "#DC143C"};					
					
					
const SPACE_COLORS_RGB = {
							ClosedSpace: {r:255, g:0, b:0}, 
							ReservedSpace: {r:255, g:255, b:126},
							FinishedSpace:  {r:0, g:176, b:80 },
							alarm: {r:255, g:87, b:51},
							warning: {r:255, g:195, b:0},
							info: {r:50, g:120, b:255},
						 }			

const TYPE_IMAGES = {info: "info.png", 
					warning: "wip.png",
					alarm: "danger.png"};

function App() {
	
  //const [userWeekDay,setUserWeekday] = useState({user:'', weekday:'', role: '', lang:''});
  
  const [tokens, setTokens] = useState(null);
//  const [selectedFloor, setSelectedFloor] = useState(null);
  const [selectedLangRoles,setSelectedLangRoles] = useState({lang:null, roles:[]});
//		
  const [key, setKey] = useState(0);
  const [alertMessage, setAlertMessage] = useState('Your server message here.');
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false);
  const [activeAlert, setActiveAlert] = React.useState(false);
  const [passiveAlert, setPassiveAlert] = React.useState(false);
  const [taskData, setTaskData] = useState([]);
  
  const [showInfo,setShowInfo] = React.useState(false);  
  const propertyMessage = useRef(null);
  
  
  const selectedFloor = useRef(null);
  const globals = useRef(null);
  const iframeRef = useRef(null);
  const src = useRef(null);
  const allTasks = useRef("");
  const trimbimModels = useRef("");
  const spaces = useRef("");
  const project = useRef("");
  const API = useRef(null);
  const selectedView = useRef(null);
  const allEmployees = useRef([]);
  const annotations = useRef([]);
    
  const selectedLang = useRef(null);
  const selectedRoles = useRef(null);
  const selectedAnnotations= useRef([]);
	
   const locationObjectIds = useRef([]);
   const locationMarkupIds = useRef([]);
   const locationIcons = useRef([]);
	
   const trbObjectMarkupIds = useRef([]);
   const trbObjectIcons = useRef([]);
   const trbObjectIds = useRef([]);

   const spaceMarkupIds = useRef([]);
   const spaceIcons = useRef([]);
   const spaceObjectIds = useRef([]);  
   
   const annotationMarkupIds = useRef([]);
   const annotationIcons = useRef([]);
   const annotatioObjectIds = useRef([]);  
  
  const spaceGuids = useRef([]);
  
  const SEARCH_PARAMS = queryString.parse(window.location.search);
  const ACCESS_TOKEN = sessionStorage.getItem("access_token");
  const REFRESH_TOKEN = sessionStorage.getItem("refresh_token");
  const PROJECT_ID = "Rc08fdHfaf8";
  const MODEL_ID = "dGhgvG66W30";
  
  const WEBCONNECT_URL = "https://web.connect.trimble.com";
  const WS_URL = window.location.origin + "/ws-message";
  

  
  useEffect(() => {
	const url1 = `/api/globals`;
	const url2 = `/allLocations`;
	const url3 = `/trimbimModels`;
	const url4 = `/spaces`;
	const url5 = `/project`;
	const url6 =  `/allUsers`;
	const url7 =  `/annotations`;
	const url8 = '/objectdata?guids=3x80IVCQuMDw%E2%80%A6qCB,0Od7Cc0HP7k9o6dvsRoO8g,03MnwscibbToHL5D39L53w%27'

	Promise.all([
        			fetch(url1),
        			fetch(url2),
        			fetch(url3),
        			fetch(url4),
        			fetch(url5),
         			fetch(url6),
         			fetch(url7),         			
         			fetch(url8)           			
      			])
      			.then(([response1, response2, response3, response4, response5,response6,response7,response8]) => {
        			return Promise.all([response1.json(), response2.json(), response3.json(), response4.json(), response5.json(), response6.json(), response7.json(), response8.json()]);
      			})
     			 .then(([data1, data2,data3,data4,data5,data6,data7,data8]) => {
					handleGlobals(data1);
        			allTasks.current = data2;
        			trimbimModels.current = data3;
        			spaces.current = data4;
        			project.current = data5;
        			allEmployees.current = data6;
        			annotations.current = data7;
        			console.log(data8);
        			initEmployees();
      			})     	
        	
  }, []);

	
	
	

	const initEmployees = () => {
		const roles =[...new Set(allEmployees.current.map(item => item.role))];
		const langs =[...new Set(allEmployees.current.map(item => item.lang))];
		selectedLang.current = langs[0];
		selectedRoles.current = [roles[0]];
	 }

   async function getCenterOfGravity(objectIDs) {
		const data = await API.current.viewer.getObjectProperties(MODEL_ID,objectIDs);
		if (!data) {
			return [];
		}
		const coordinates = [];
		let calculatedGeometry = null;
		for (let i = 0; i< data.length ; i++) {
			calculatedGeometry =  data[i].properties.filter(x => x.name === "CalculatedGeometryValues");	   
			if (calculatedGeometry) {
				const xObj =  calculatedGeometry[0].properties.filter(x => x.name === "CenterOfGravityX");
				const yObj =  calculatedGeometry[0].properties.filter(x => x.name === "CenterOfGravityY");
				const zObj =  calculatedGeometry[0].properties.filter(x => x.name === "CenterOfGravityZ");
				coordinates.push({x:xObj[0].value,y:yObj[0].value,z:zObj[0].value});
			} else {
				return [];
			}		
		}
		return  coordinates;		
   }

   async function asyncGetDimensions(spaceIDs) {
		const data = await API.current.viewer.getObjectProperties(MODEL_ID,spaceIDs);
		if (!data) {
			return [];
		}
		const dimensions = [];
		let dimensionProperties = null;
		for (let i = 0; i< data.length ; i++) {
			let area = 0;
			let volume = 0;
			let unboundedHeight = 0;
			dimensionProperties =  data[i].properties.filter(x => x.name === "CalculatedGeometryValues");	   
			if (dimensionProperties) {
				area =  dimensionProperties[0].properties.filter(x => x.name === "Area");
				//const perimeter =  dimensionProperties[0].properties.filter(x => x.name === "Perimeter");
				volume =  dimensionProperties[0].properties.filter(x => x.name === "Volume");	
			} else {
				return [];
			}	
			dimensionProperties =  data[i].properties.filter(x => x.name === "OrientedBoundingBox");	
			if (dimensionProperties) {
				unboundedHeight =  dimensionProperties[0].properties.filter(x => x.name === "YY");	
			} else {
				return [];
			}
			dimensions.push({area:area[0].value, volume:  volume[0].value ,  unboundedHeight: Math.abs(unboundedHeight[0].value)}); 	
		}
		return  dimensions;		
   }

	function delay(milliseconds){
    	return new Promise(resolve => {
        	setTimeout(resolve, milliseconds);
    	});
    }

    function toSelector(modelObjects) {
      return { modelObjectIds: modelObjects.map(m => ({ modelId: m.modelId, objectRuntimeIds: m.objects.map(o => o.id).sort() })).sort((a, b) => a < b ? -1 : a > b ? 1 : 0) };
    }
  
    
     async function asyncHideSpaces(objects) {
		const objectSelector = toSelector(objects);
		const visible = false;
		await API.current.viewer.setObjectState(objectSelector, {visible});
		console.log("setObjectState OK");
	}


    async function removeSpaceIconsMarkups() {
	  //let icons = await API.current.viewer.getIcon();
	  await API.current.viewer.removeIcon(spaceIcons.current); 
	  //let markups = await API.current.markup.getTextMarkups();
	  await API.current.markup.removeMarkups(spaceMarkupIds.current);
	  spaceIcons.current = [];
	  spaceMarkupIds.current = [];
	}

	// NOTE: this hides ALL the spaces in view
	async function hideAllSpaces() {
		const objects = await API.current.viewer.getObjects({parameter: {class : 'IFCSPACE'}});
		await asyncHideSpaces(objects); 
		removeSpaceIconsMarkups();
	}
	
//
//     async function asyncShowSpaceObjects(spaceGuids,types){
//		await asyncColorObjects(spaceGuids, SPACE_COLORS_RGB[types[0]], 127, spaceObjectIds.current);	
//	}



	async function asyncChangeView(viewId) {
		await API.current.view.selectView(viewId);
		await delay(1000);
		await hideAllSpaces();
	}

	async function asyncChangeFloor(floorNbr) { 
		selectedFloor.current = floorNbr;
		const viewId = project.current.floors[floorNbr -1].viewId;
		selectedView.current = viewId;
		removeAllIcons();
		removeAllMarkups();
		await asyncChangeView(viewId);
		if (selectedFloor.current === null || floorNbr === selectedFloor.current) {
			showLocations(selectedRoles.current,selectedLang.current);
			await delay(1000);
			updateCheckedAnnotations(selectedAnnotations.current);
		}

		//hidelDoorsAndLoadBearing(locationObjectIds.current);
		if (floorNbr !=  3 && floorNbr !== 4)   {
			//showAllAsFinished();
		}

	}


     async function asyncShowTrimBimModels(trimbimModelIds,trimbimModelNames, trimbimModelGuids, ifcSpaceGuids, color){
		//await asyncChangeView('jKYMr2d38ko');
		// first get runtime ids for ifcspaces
		let ifcSpaceRunTimeIds = [];
		if (!API.current) {
			return;
		}
//		const modelObjects = await API.current.viewer.getObjects()
//		for (const mObj of modelObjects) {
//			if (mObj.modelId !== MODEL_ID) {
//				continue;
//			}
//		    const runtimeIds = mObj.objects.map(o => o.id);
//		    const  externalIds = await API.current.viewer.convertToObjectIds(mObj.modelId, runtimeIds)
//			if (runtimeIds.length !== externalIds.length) {
//		          console.log("FAILED: Cannot resolve all external IDs");
//		    }
//		          		
//		    externalIds.forEach(function(extId,ind) {
//				if(ifcSpaceGuids.includes(extId)) {
//					let id = runtimeIds[ind];
//					ifcSpaceRunTimeIds.push(id);
//				}	
//			});
//		}
		
		ifcSpaceRunTimeIds = await API.current.viewer.convertToObjectRuntimeIds(MODEL_ID,ifcSpaceGuids);
		
		
//		// then get runtimeids for trimbimmodelobjects
//		// note the runtime ids are just sequence numbers within a model: there may be same ids for spaces and modelobjects
//		let trimbimRuntimeIds = [];
//		await API.current.viewer.toggleModel(trimbimModelIds, true); // lets make sure that all the trimbimmoidels are loaded
//		const objs = await API.current.viewer.getObjects();
//		
//		for (const mObj of objs) {
//			if (mObj.modelId === MODEL_ID && mObj.modelId !== trimbimModelIds[0]) {
//				continue;
//			}
//			const runtimeIds = mObj.objects.map(o => o.id);
//			const externalIds = await API.current.viewer.convertToObjectIds(mObj.modelId, runtimeIds);
//			if (runtimeIds.length !== externalIds.length) {
//		            		console.log("FAILED: Cannot resolve all external IDs");
//		     }
//		          		
//		     externalIds.forEach(function(extId,ind) {
//			 	if(trimbimModelGuids.includes(extId)) {
//					let id = runtimeIds[ind];
//					trimbimRuntimeIds.push(id);
//					trbObjectIds.current.push(id);
//			  	}	
//			})
//		}

		await API.current.viewer.toggleModel(trimbimModelIds, true, true); // lets make sure that all the trimbimmoidels are loaded
		let trimbimRuntimeIds = [];
		for (let i = 0; i< trimbimModelIds.length; i++) {	
			let modelId = trimbimModelIds[i];
			let guid = trimbimModelGuids[i];

			let ids = await API.current.viewer.convertToObjectRuntimeIds(modelId,[guid]);
			trimbimRuntimeIds.push(...ids );
		};
					
		if (trimbimRuntimeIds.length > 0 && ifcSpaceRunTimeIds.length > 0) {
			
			for (let i = 0; i<ifcSpaceRunTimeIds.length; i++ ) {
				const coordinates = await getCenterOfGravity([ifcSpaceRunTimeIds[i]]);
				const dimensions = await asyncGetDimensions([ifcSpaceRunTimeIds[i]]);
				function randomIntFromInterval(min, max) { // min and max included 
  					return Math.floor(Math.random() * (max - min + 1) + min)
				}

				const id = randomIntFromInterval(0, 10000);
				const x = coordinates[0].x;
				const y = coordinates[0].y;
				const z = coordinates[0].z - dimensions[0].unboundedHeight/2.0 + 200; // height seems to be too big: move 20 cm up to see whole model
						
				await API.current.viewer.placeModel(trimbimModelIds[i], {position: {x: x,y: y, z: z}});
				const objectSelector = {modelObjectIds: [{modelId: trimbimModelIds[i] , objectRuntimeIds: trimbimRuntimeIds}]};
				const objectState = {color: {r:color.r, g:color.g, b: color.b, a:255}};
				await API.current.viewer.setObjectState(objectSelector, objectState);
				const zdistance = 5000;
				const marker = {
	      			color: {a:255, b:0, g:0, r:255},
	        		start: {positionX: x,positionY: y,positionZ: z},
	        		end: {positionX:x, positionY:y, positionZ:z + zdistance},
					text: trimbimModelNames[i],
					id: id
	    		}						
	  			const addedMarkups = await API.current.markup.addTextMarkup([marker]);
	  				addedMarkups.forEach(function(markup) {
						trbObjectMarkupIds.current.push(markup.id);	
				});
			}
		}
	}
   
   async function asyncShowModels(selectedModelIds) {
	 const modelIds = [];
	 const modelNames = [];
	 const modelGuids = [];
	 const modelIfcSpaceGuids = [];
	 await hideModels();
	 trimbimModels.current.forEach(function(model) {
    	if (model.viewId === selectedView.current && selectedModelIds.includes(model.trimbimModelId)) {
			modelIds.push(model.trimbimModelId);
			modelNames.push(model[selectedLangRoles.lang]);
			modelGuids.push(model.trimbimModelGuid);
			modelIfcSpaceGuids.push(model.ifcSpaceGuid);
		}
	});
	await asyncShowTrimBimModels(modelIds,modelNames,modelGuids, modelIfcSpaceGuids, COLORS_RGB.object);
  }
  
  
  async function hideModels() {
	  if (API.current) {
		  const trimbimModelIds = trimbimModels.current.map(model => model.trimbimModelId);
		  await API.current.viewer.toggleModel(trimbimModelIds, false);
		  await API.current.markup.removeMarkups(trbObjectMarkupIds.current);
	  }
	  trbObjectIds.current = [];
	  trbObjectMarkupIds.current = [];
  }




    async function asyncColorObjects(guids,spaceGuid, color, alpha,idRefs,spaceGuidrefs) {
		
		console.log("asyncColorObjects: " + spaceGuid);
		
		 const objs = await API.current.viewer.getObjects();		 
	     for (const mid of objs) {
	     	const runtimeIds = mid.objects.map(o => o.id);
	        const externalIds = await API.current.viewer.convertToObjectIds(mid.modelId, runtimeIds)
			if (runtimeIds.length !== externalIds.length) {
	    		mid["FAILED"] = "FAILED: Cannot resolve all external IDs";
			}
	        let objectRuntimeIds = [];
	       	externalIds.forEach(function(extId,ind) {
				if(guids.includes(extId)) {
							let id = runtimeIds[ind];
							objectRuntimeIds.push(id);
							idRefs.push(id);
				}	
			});
			if (objectRuntimeIds.length === 0) {
				continue;
			}
			const objectSelector = {modelObjectIds: [{modelId: mid.modelId , objectRuntimeIds: objectRuntimeIds}]};
			const objectState = {color: {r:color.r, g:color.g, b: color.b, a:alpha}, visible:true};
			await API.current.viewer.setObjectState(objectSelector, objectState);
	    }
	    
	    if (spaceGuid !== null) {
			const spaceId = await runtimeIdsFromGuids([spaceGuid]);
			const objectSelector = {modelObjectIds: [{modelId: MODEL_ID, objectRuntimeIds: spaceId}]};
			const objectState = {color: {r:color.r, g:color.g, b: color.b, a:70}, visible:true};
			await API.current.viewer.setObjectState(objectSelector, objectState);
			spaceGuidrefs.push(spaceGuid);
		}
	    
	}
	  
  // if no url parameters, this renders button to login
  // if url parameters contains authorization code (redirected from AUTHORIZATION_URL) it is redirected to Authorize 
  // if url parameters contain accessToken (redirected from Authorize), Iframe src is set to viewer with accesstoken, refreasToken and projectId
  const handleGlobals = (data) => {	
	globals.current = data;
	var lastChar = data.redirect_uri.substr(-1); 
	if (lastChar !== '/') {         // If the last character is not a slash
   		data.redirect_uri = data.redirect_uri + '/';	
	}
	
    if (window.location.search) {
		if (SEARCH_PARAMS.code) {
			window.location.href =  globals.current.redirect_uri + 'authorize?code=' + SEARCH_PARAMS.code;
   		} else if (SEARCH_PARAMS.authenticated) {
			setTokens({accessToken: sessionStorage.getItem("access_token"), refreshToken: sessionStorage.getItem("refresh_token"), idToken: sessionStorage.getItem("id_token") });
	  		src.current= WEBCONNECT_URL + "/?isEmbedded=true";
    	}
	} 
  };


  
  async function runtimeIdsFromGuids(guids) {
	  const objectRuntimeIds = await API.current.viewer.convertToObjectRuntimeIds(MODEL_ID, guids);
	  return objectRuntimeIds;
  }
  
   async function removeAllMarkups() {
	  await API.current.markup.removeMarkups(); 
	  locationMarkupIds.current = [];
	  spaceMarkupIds.current = [];
	}
  
     async function removeAllIcons() {
	  await API.current.viewer.removeIcon(); 
	  locationIcons.current = [];
	  spaceIcons.current = [];
	}
  
     async function removeLocationMarkups() {
	   await API.current.markup.removeMarkups(locationMarkupIds.current);
	  locationMarkupIds.current = [];
	}
  
  
   async function removeLocationIcons() {
	  await API.current.viewer.removeIcon(locationIcons.current); 
	  locationIcons.current = [];
	}
 
 
    async function removeAnnotationMarkups() {
	   await API.current.markup.removeMarkups(annotationMarkupIds.current);
	  annotationMarkupIds.current = [];
	}
  
  
   async function removeAnnotationIcons() {
	  await API.current.viewer.removeIcon(annotationIcons.current); 
	  annotationIcons.current = [];
	}
 
 	function getRandomInt(min, max) {
    	min = Math.ceil(min);
    	max = Math.floor(max);
    	return Math.floor(Math.random() * (max - min + 1)) + min;
	}	
 
  async function addIconMarkups(guids, texts, images,iconRefs,markupIdRefs) {
	  	const runtimeIds = await runtimeIdsFromGuids(guids);
	  	const coordinates = await getCenterOfGravity(runtimeIds);
	  	const markups = [];
	  	const icons = [];
	  	// Note: icon coordinates are in meters, while annotation coonrdinates are in mm
	  	coordinates.forEach(function(position,ind) {				
			const guid = guids[ind];
		    const iconId = runtimeIds[ind]; 
		    var ann = annotations.current.filter(function (el) {return  el.objectGuid === guid});
		    if (ann.length > 0) {
				ann[0].iconId = iconId;	
			}
			
			if (images.length > ind) {
				const url =  window.location.origin + '/images/' + images[ind];	
				const icon = {
					id: iconId, // NOTE: iconClick id return this as ProvidedId
					iconPath: url,
					position: { x: position.x/1000, y: position.y/1000, z: position.z/1000 + 2 },
          			size: 44
        		}	
        		icons.push(icon);
        		iconRefs.push(icon);

			}	

			const markupId =getRandomInt(1, 100000); 
			if (texts.length > ind) {
				const markup = {
      				color: {a:255, b:255, g:128, r:0},
        			start: {positionX: position.x,positionY: position.y,positionZ: position.z},
        			end: {positionX: position.x , positionY: position.y , positionZ:position.z + 3000},							
					text: texts[ind],
					id: markupId // NOTE: this does not set id as promised in API
        		}
        		markups.push(markup);
			}
									
   		});	
 	if (icons.length > 0) {
      	await API.current.viewer.addIcon(icons);
    }
    if (markups.length > 0) {
		let addedMarkups = await API.current.markup.addTextMarkup(markups);
		addedMarkups.forEach(function(markup,ind) {
			markupIdRefs.push(markup.id);	
		});
//		console.log(markupIdRefs);
	}
 }
  



  
  
//  async function asyncShowSpaces()  {
//	  
//	 const hack = [ {type: 'nogo', image: 'nogo.png', text: "NO GO"},
// 					{type: 'reserved', image: 'wip.png', text: "Reserved"},]; 
//	  
//	 const texts = [];
// 	  const images = [];
//	  for (let i = 0; i< spaces.current.length; i++) {
//		  let space = spaces.current[i];
//		  const item = hack.find((obj) => obj.type === space.type);
//		  texts.push(item.text);
//		  images.push(item.image);
//		  if (space.viewId === selectedView.current) {
//			  await asyncShowSpaceObjects([spaces.current[i].spaceGuid],[spaces.current[i].type]);
//		  }		  	  
//	  }
//	  const guids = spaces.current.map(space => space.spaceGuid);				  
//	  addIconMarkups(guids, texts,images,spaceIcons.current,spaceMarkupIds.current);
//  }

	const showInfoDialog = async (guids) => {
		const runtimeIds = await runtimeIdsFromGuids(guids);
		API.current.viewer.convertToObjectIds(MODEL_ID, runtimeIds)
			.then((response) => {
				getProperties(response)
				.then((responseProperties) => {
					propertyMessage.current = responseProperties;
					setShowInfo(true);
				});
			});
	}



//todo: REMOVE DELAY HACK
	async function updateCheckedLocations(locationIds) {
		removeLocationIcons();
		removeLocationMarkups(); 
		let objectSelector =  { modelObjectIds: [{ modelId: MODEL_ID, objectRuntimeIds: spaceObjectIds.current}]};	
		const visible = false;
		API.current.viewer.setObjectState(objectSelector, {visible}).then(
			API.current.viewer.setObjectState({ modelObjectIds: [{ modelId: MODEL_ID, objectRuntimeIds: locationObjectIds.current}]}, {visible})	
		)	
		await delay(1000);	
//		objectSelector =  { modelObjectIds: [{ modelId: MODEL_ID, objectRuntimeIds: locationObjectIds.current}]};
	    
	    await delay(1000);	
		API.current.viewer.convertToObjectRuntimeIds(MODEL_ID,spaceGuids.current).then((spaceIds) => {
					API.current.viewer.setObjectState({ modelObjectIds: [{ modelId: MODEL_ID, objectRuntimeIds: spaceIds}]}, {visible}).then (
						asyncShowLocations(locationIds)
					)
		}
		);

		
		// NOTE: this resets ALL the colored objects and 
		// sets color for objects from locationlist        
		//const objectState = {color: 'reset'};
		//await API.current.viewer.setObjectState(null,{visible});
		//hidelDoorsAndLoadBearing(locationObjectIds.current);
	}
	
	const updateCheckedAnnotations = (annotationIds) => {
		selectedAnnotations.current = annotationIds;
		if (!API.current) {
			return;
		}
		removeAnnotationIcons(); 
		removeAnnotationMarkups(); 
		const objectState = {color: 'reset'};
		API.current.viewer.setObjectState(null,objectState);
		asyncShowAnnotations(annotationIds);
	}

	const showLocations = (roles, lang) => {
		
		let data = allTasks.current;
	  	const dataWithId = data.map((el, index) => {
  			return {
    			...el,
    			id: index + 1,
    			task: el[lang]
  			};
			});
  	  	let filteredData = dataWithId.filter(function (el) {return el.type !== "NOT"});
	  													   
	 	function compare( a, b ) {
			const order = ["Proposed","Defined","Started","Finished","NOT"];
			return order.indexOf(a.type) - order.indexOf(b.type);
		}	

		filteredData = filteredData.filter(function (el) {return (roles.includes(el.worker) || el.worker === "ALL") && el.floor === selectedFloor.current});
	
		filteredData.sort(compare); 												   
	 	setTaskData(filteredData); // taskdata is sorted and filtered by selected professions
	}

  async function asyncShowLocations(locationIds) {
	  
	console.log("asyncShowLocations");  
	  
	// taskdata ihas been sorted and filtered by selected professions
	const filteredData = taskData.filter(function (el) {return locationIds.includes(el.id.toString())});
	
	var proposedData = filteredData.filter(function (el) {return  el.type === "Proposed" && locationIds.includes(el.id.toString())});
	var startedData = filteredData.filter(function (el) {return el.type === "Started" && locationIds.includes(el.id.toString())});
	var definedData = filteredData.filter(function (el) {return el.type === "Defined" && locationIds.includes(el.id.toString())});
	var finishedData = filteredData.filter(function (el) {return el.type === "Finished" && locationIds.includes(el.id.toString())});
	var spaceData = filteredData.filter(function (el) {return (el.type === "ClosedSpace" || el.type === "ReservedSpace" || el.type === "FinishedSpace") && locationIds.includes(el.id.toString())});
	locationObjectIds.current = [];  			
	locationIcons.current = [];		
	locationMarkupIds.current = [];	
	  			
	  													   
	 if (definedData.length > 0) {
		for (let i = 0; i< definedData.length; i++) {		
			await asyncColorObjects(definedData[i].guids,definedData[i].spaceId, COLORS_RGB.defined, 255, locationObjectIds.current,spaceGuids.current);
			await addIconMarkups([definedData[i].spaceId], [definedData[i].room], [definedData[i].images[0]],locationIcons.current,locationMarkupIds.current); // show only one since they may be too close to each other
		}
	}; 
  	if (proposedData.length > 0) { 
		for (let i = 0; i< proposedData.length; i++) {
			await asyncColorObjects(proposedData[i].guids,proposedData[i].spaceId,  COLORS_RGB.proposed, 255, locationObjectIds.current,spaceGuids.current);
			await addIconMarkups([proposedData[i].spaceId],[proposedData[i].room], [proposedData[i].images[0]],locationIcons.current,locationMarkupIds.current);
		}
	}; 
	if (startedData.length > 0) {
		for (let i = 0; i< startedData.length; i++) {
			await asyncColorObjects(startedData[i].guids, startedData[i].spaceId, COLORS_RGB.started, 255, locationObjectIds.current,spaceGuids.current);
			await addIconMarkups([startedData[i].spaceId],[startedData[i].room], [startedData[i].images[0]],locationIcons.current,locationMarkupIds.current);
		}
	}; 
	if (finishedData.length > 0) {
		for (let i = 0; i< finishedData.length; i++) {
			await asyncColorObjects(finishedData[i].guids,finishedData[i].spaceId, COLORS_RGB.finished, 255, locationObjectIds.current,spaceGuids.current);
			await addIconMarkups([finishedData[i].spaceId],[finishedData[i].room], [],locationIcons.current,locationMarkupIds.current);
		}
	}; 
	
	
	if (spaceData.length > 0) { 
		for (let i = 0; i< spaceData.length; i++) {
			await asyncColorObjects(spaceData[i].guids,null ,SPACE_COLORS_RGB[spaceData[i].type], 127, spaceObjectIds.current,null);
			await addIconMarkups([spaceData[i].guids[0]],[spaceData[i].room], [spaceData[i].images[0]],locationIcons.current,locationMarkupIds.current);
		}
	}; 


  };

  async function asyncShowAnnotations(annotationIds) {	  
	
	var objectData = annotations.current.filter(function (el) {return  el.objectType === "IfcBuildingElement" && annotationIds.includes(el.id.toString())});
	var spaceData = annotations.current.filter(function (el) {return el.objectType === "IfcSpace" && annotationIds.includes(el.id.toString())});
	annotationMarkupIds.current = [];  			
	annotatioObjectIds.current = [];
	  			
	let lang = selectedLangRoles.lang;
	  			
	if (objectData.length > 0) {
		for (let i = 0; i< objectData.length; i++) {
			const type = objectData[i].type.toLowerCase();
			await asyncColorObjects(objectData[i].objectGuid,null, COLORS_RGB.finished, 255, annotatioObjectIds.current,null);
			await addIconMarkups([objectData[i].objectGuid],[objectData[i][lang]],null, [TYPE_IMAGES[type]],annotationIcons.current,annotationMarkupIds.current,null);
		}
	}; 
	
	if (spaceData.length > 0) { 
		for (let i = 0; i< spaceData.length; i++) {
			const type = spaceData[i].type.toLowerCase();
			await asyncColorObjects([spaceData[i].objectGuid],null ,SPACE_COLORS_RGB[spaceData[i].type], 127, annotatioObjectIds.current);
			await addIconMarkups([spaceData[i].objectGuid],[spaceData[i][lang]],[TYPE_IMAGES[type]],annotationIcons.current,annotationMarkupIds.current);
		}
	}; 

  };



	const login = () => {
		handleLogin(globals);
	}


  const handleLogin = (globals) => {
	  
	let uri = globals.current.redirect_uri;
	
	// this is hack to deal with trailing /
	if (uri.includes("actor.vtt.fi")) {
		uri = "https://actor.vtt.fi";
	}
	if (uri.includes("localhost")) {
		uri = "http://localhost/";
	}
	const AUTHORIZATION_URL = AUTHORIZATION_ENDPOINT + '?response_type=code&client_id=' + globals.current.client_id +'&redirect_uri=' + uri + '&scope=' + SCOPE;
    window.location.href = AUTHORIZATION_URL;
  };
  
  async function  initUI() {
	    await API.current.embed.setTokens({accessToken: ACCESS_TOKEN, refreshToken: REFRESH_TOKEN  });   	
	    //await API.current.embed.setTokens({ACCESS_TOKEN,REFRESH_TOKEN  });   
     	await API.current.embed.init3DViewer({projectId: PROJECT_ID}); 
     	await API.current.ui.setUI({name: "MainToolbar",state: "hidden"});
     	await API.current.ui.setUI({name: "SidePanel",state: "hidden"});
     	await  API.current.viewer.toggleModel(MODEL_ID);
     	await asyncChangeFloor(5);
  }


  const handleFrameLoaded = () => {
	const iframeWindow = iframeRef.current.contentWindow;
    const connect = async () => {
	 	API.current = await WorkspaceAPI.connect(iframeWindow, 
       		(event, args) => {
         		console.log(event, args);
         		if (event === "extension.accessToken") {
         			window.alert('extension.accessToken');
         		}
         		if (event === "embed.session.invalid") {
					login();
				 }
				 if (event === "viewer.onIconPicked") {
					 console.log(args);
					 var ann = annotations.current.filter(function (el) {return  el.iconId === args.data[0].providedId});					 
					 if (ann.length > 0) {
						 onAlertReceived(ann[0],);				 	
					} else {
						API.current.viewer.convertToObjectIds(MODEL_ID, [args.data[0].providedId])
						.then((response) => {
							const task = allTasks.current.filter(obj => obj.spaceId === response[0]);	
							getProperties(task[0].guids)
							.then((responseProperties) => {
								propertyMessage.current = responseProperties;
								setShowInfo(true);
							});
						});
						
					}

				 }
       		}
     	);
     	
		initUI();
  	}
  	 connect()
      .then((res) => {
		console.log("##################################################################### connected");
      })
      .catch((e) => {
        console.log(e);		
      });
  };




  
  const onConnected = () => {
    console.log("Connected!!")
  }
  
  
  const getAlertMessage = () => {  
	   let text = alertMessage.detail;
		let lang = selectedLangRoles.lang;
		if ( lang === "eng") {
			text = alertMessage.engDetail;
		} else if (lang === "fin") {
			text = alertMessage.finDetail;
		} else if (lang=== "est") {
			text = alertMessage.estDetail;
		}
		console.log(alertMessage);
	  return text;
  }

  const onAlertReceived = (msg) => {
	  
	if (msg.message) {
		setAlertMessage(msg.message);
	}  else {
		setAlertMessage(msg);
	}

    setActiveAlert(true);
    setOpenAlertDialog(true);
  }
  

  
  const handleAlertDialogClose = (event, reason) => {
	  if (reason && reason == "backdropClick") {
        return;
       }
	  setOpenAlertDialog(true);
	  setActiveAlert(false);
//	  setPassiveAlert(true);
  }
  
   const handleInfoDialogClose = (event, reason) => {
	  if (reason && reason == "backdropClick") {
        return;
       }
		setShowInfo(false);
//	  setPassiveAlert(true);
  }
  
//  const handlePassiveDialogClose = (event, reason) => {
//	  if (reason && reason == "backdropClick") {
//        return;
//       }
//	  setPassiveAlert(false);
//  }
  

  const  hideWebConnectUI = () =>  {
	 API.current.ui.setUI({name: "MainToolbar",state: "hidden"});
     API.current.ui.setUI({name: "SidePanel",state: "hidden"}); 
  } 



 	async function  showAllAsFinished() {	
		 
		const walls = await API.current.viewer.getObjects({parameter: {class: "IFCWALL"}});
        const wallstandards = await API.current.viewer.getObjects({parameter: {class: "IFCWALLSTANDARDCASE"}}); 
        
        const wallobjects = walls[0].objects.map(obj => obj.id);
        const wallstandardobjects = wallstandards[0].objects.map(obj => obj.id);
        let objects = [...wallobjects,...wallstandardobjects]; 
        let properties = await API.current.viewer.getObjectProperties(MODEL_ID, objects)
        
        let filteredProperties = properties.filter(obj => getCenterOfGravity(obj)  > 30000 && getCenterOfGravity(obj)  < 34000  );					 
												 									 
		const filteredObjectIds = filteredProperties.map(obj => obj.id);
		const objectSelector =  { modelObjectIds: [{ modelId: MODEL_ID, objectRuntimeIds: filteredObjectIds}]};
		
		const color = COLORS_RGB.finished;
		
		const objectState = {color: {r:color.r, g:color.r, b: color.r, a:5255}};
		API.current.viewer.setObjectState(objectSelector, objectState);
        
	}


  async function  hidelDoorsAndLoadBearing(untouchedWalls) {	  
        	const walls = await API.current.viewer.getObjects({parameter: {class: "IFCWALL"}});
        	const wallstandards = await API.current.viewer.getObjects({parameter: {class: "IFCWALLSTANDARDCASE"}});
        	const doors = await API.current.viewer.getObjects({parameter: {class: "IFCDOOR"}});
        	const modelId = walls[0].modelId;
        	const wallobjects = walls[0].objects.map(obj => obj.id);
        	const wallstandardobjects = wallstandards[0].objects.map(obj => obj.id);
        	const doorobjects = doors[0].objects.map(obj => obj.id);
        	let objects = [...wallobjects,...wallstandardobjects,...doorobjects];       	
        	objects = objects.filter(obj => !untouchedWalls.includes(obj)); 
        	
			API.current.viewer.getObjectProperties(modelId, objects)
			.then((properties) => {
//				let filteredByFloor = properties.filter(obj => 
//    											obj.properties.some(property => property.name === "Constraints" && 
//    																	        property.properties.some(prop => prop.name=== "Base Constraint" && prop.value === "Level: "  + selectedFloor.current) ||
//    																	        property.properties.some(prop => prop.name=== "Level" && prop.value === "Level: " + selectedFloor.current))
//										 				);
				let filteredProperties = properties.filter(obj => 
												obj.class === "IFCDOOR" ||
    											obj.properties.some(property => property.name === "Pset_WallCommon" && 
    																	        property.properties.some(prop => prop.name=== "LoadBearing" && prop.value != 1)));					 
										 									 
				const filteredObjectIds = filteredProperties.map(obj => obj.id);
				const objectSelector =  { modelObjectIds: [{ modelId: modelId, objectRuntimeIds: filteredObjectIds}]};
				const objectState = {color: {r:255, g:255, b: 255, a:50}};
				API.current.viewer.setObjectState(objectSelector, objectState);
				console.log(filteredProperties);
			})
			.catch(error => {
	        	console.log(error);
	    	});

				
        
  } 

  
	async function  getProperties(guids) {
		
		const url = `/api/objectdata`;
		const queryParams = `?guids=${guids.join(',')}`;
		const url2 = url + queryParams;
//		
//    		fetch(url2).
//    			then(response => response.json()).
//    			then(data => {
//					console.log(data);
//				})
//				.catch(error => {
//        			console.error('Error fetching data:', error);
//    			});
   		fetch(url2).
    			then(response => console.log(response));
	
			
		const objectRuntimeIds = await runtimeIdsFromGuids(guids);
		const values = await  API.current.viewer.getObjectProperties(MODEL_ID, objectRuntimeIds);
		
		const pSetNames = ["CalculatedGeometryValues","CustomProfile"];
		const pNames = ["Volume","Area","ProfileName"];
		let products = [];
		for (let i= 0; i <values.length; i++) {
			let obj = values[i];
			if (obj.class !== 'IFCSPACE') {
				let product = {};
				product.name = obj.product.name;
				let propertySets = obj.properties.filter(obj => pSetNames.includes(obj.name));
				let properties= [].concat(...propertySets.map(o => o.properties));
				product.properties = properties.filter(obj => pNames.includes(obj.name));
				products.push(product);	
			}
		}
		return ([...products]);
	}
  
  
  return (
	  <div>
      		{tokens ? (
        		<>	 
        			<Header changeFloor={asyncChangeFloor} selectedFloor ={selectedFloor.current} hideWebConnectUI={hideWebConnectUI} 
        					selectedLangRoles ={selectedLangRoles} setSelectedLangRoles={setSelectedLangRoles} showLocations={showLocations}
        					getObjectsByProperties={hidelDoorsAndLoadBearing}/>
     				 <LeftMenu project = {project} changeFloor={asyncChangeFloor} selectedFloor ={selectedFloor.current} 
     				 			showLocations = {showLocations} setSelectedLangRoles={setSelectedLangRoles} selectedLangRoles={selectedLangRoles}/>
     				<Grid container spacing={3} sx={{ height:'1000px', marginBottom:'30px' }} >		     			
      					<Grid item sm={12} md={9}>
      						<iframe key = {key} ref={iframeRef}  id="viewerFrame" sandbox="allow-scripts allow-modals allow-forms allow-same-origin allow-popups" src={src.current} onLoad={handleFrameLoaded} />  
          				</Grid>
          				 <Grid item md={3}>
        				 	<RightAccordion taskData={taskData} updateCheckedLocations={updateCheckedLocations} trimbimModels = {trimbimModels.current}
        				 		asyncShowModels={asyncShowModels} hideModels= {hideModels} colors = {COLORS_HEX} selectedLangRoles={selectedLangRoles}
        				 		annotations={annotations.current} setAlarm={onAlertReceived} updateCheckedAnnotations={updateCheckedAnnotations}
        				 		showInfoDialog={showInfoDialog} />
          				</Grid>
     				</Grid>    				

     				<div>
					   <SockJsClient
					        url={WS_URL}
					        topics={['/topic/alert']}
					        onConnect={onConnected}
					        onDisconnect={console.log("Disconnected!")}
					        onMessage={msg => onAlertReceived(msg)}
					        debug={false}
					      />
					</div>
        		</>
      			) : (
					<Login handleLogin={login} />  
      			)}
      			
      			
      			
      		{activeAlert ? (
				<Dialog
        			open={openAlertDialog}
        			onClose={handleAlertDialogClose}
        			aria-labelledby="alert-dialog-title"
        			aria-describedby="alert-dialog-description"
      			>
        			<DialogContent>
          				<DialogContentText id="alert-dialog-description">
          					<Alert onClose={handleAlertDialogClose} variant="filled" severity="error">{getAlertMessage()}</Alert>						
          				</DialogContentText>
        		</DialogContent>
      			</Dialog>
			  ) : null			  
			 }	
			 
			 {showInfo ? (
				<Dialog
        			open={showInfo}
        			onClose={handleInfoDialogClose}
        			aria-labelledby="alert-dialog-title"
        			aria-describedby="alert-dialog-description"
      			>
        			<DialogContent>
          				<DialogContentText id="alert-dialog-description">
          					<Alert onClose={handleInfoDialogClose} severity="info">
          						<InfoTable message = {propertyMessage.current} selectedLangRoles={selectedLangRoles}/>
          					</Alert>						
          				</DialogContentText>
        		</DialogContent>
      			</Dialog>
			  ) : null			  
			 }		
    </div>
  );
}


export default App;
