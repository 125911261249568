import React, { useRef,useEffect } from 'react';
import queryString from 'query-string';
import axios from 'axios';
import { createSearchParams, useNavigate } from 'react-router-dom';

const TOKEN_ENDPOINT = 'https://id.trimble.com/oauth/token';
  



function Authorize() {
	const navigate = useNavigate();
	const  params = queryString.parse(window.location.search);
	const globals = useRef(null);
  useEffect(() => {

    // check if the URL has a code parameter
    
    if (params) { 
		const code = params.code;
		if (code) {
			let url = `/api/globals`;
      		fetch(url)
        		.then((response) => response.json())
        		.then((data) => handleGlobals(data,code))
        		.catch(error => console.error(error));
      		
		}
  }}, [params]);

  const handleGlobals = (data,code) => {
	console.log(data);  	
   globals.current = data;

    
    if (globals.current.redirect_uri.includes("actor.vtt.fi")) {
		globals.current.redirect_uri = "https://actor.vtt.fi";
	}
    if (globals.current.redirect_uri.includes("localhost")) {
		globals.current.redirect_uri = "http://localhost/";
	}
 	console.log(globals.current);
    const options = {
   		method: 'POST',
    	url: TOKEN_ENDPOINT,
		headers: {
    		'Content-Type': 'application/x-www-form-urlencoded'
  		},
   		data:{
           	grant_type: 'authorization_code',
           	code:code,
        	redirect_uri: globals.current.redirect_uri,
        	client_id: globals.current.client_id ,
        	client_secret: globals.current.client_secret
       	},    				
	};
    axios
    .request(options)
    .then(function (response) {
       	sessionStorage.setItem("access_token", response.data.access_token);
       	sessionStorage.setItem("refresh_token", response.data.refresh_token);
       	sessionStorage.setItem("id_token", response.data.id_token);
       	navigate({
    		pathname: "/",search: createSearchParams({authenticated:"OK"}).toString()
		})	 	
    })
    .catch(function (error) {
    console.error('Failed to obtain access token:', error);
    });
  };


  return (
    <div>
      <p>Authenticating...</p>
    </div>
  );
}


export default Authorize;