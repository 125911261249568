import React from 'react';
import './index.css';
import RoutesComponent from './RoutesComponent';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RoutesComponent />
);


// create-react-app automatiucally generates strictmode rendering
// it cannot be used because it renders all components  twice, causing Authorize quey token twice, using same code
//root.render(
//  <React.StrictMode>
//    <RoutesComponent />
//  </React.StrictMode>
//);