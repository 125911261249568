import React, { useState, useEffect, useRef } from "react";
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import logo from './logo192.png';


function Login(props) {
	  const {handleLogin} = props;

  return (
    <AppBar position="static">
      	<Toolbar style={{ backgroundColor: "#00ccff" }}>
      		<img src={logo} alt="Logo" width="192" />
         	{handleLogin? <Box m={2} key={500}><Button variant="contained" color="success" onClick={() => handleLogin()}>{"Login"}</Button></Box>: null}
		</Toolbar>
    </AppBar>
  );
}

export default Login;