
import React, { useState, useEffect , useRef} from "react";
import {
   	Header
}  from './modules/index.js';
import { Grid, Button} from "@mui/material";			
import SockJsClient from 'react-stomp';
import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';

function Manage() {
	const [employees, setEmployees] = useState(null);
	const [checkedEmployee, setCheckedEmployee] = useState(null);
	//  	const checkedEmployees = useRef([]);
  	//const SOCKET_URL = 'http://localhost:8080/ws-message';
  	const SOCKET_URL = window.location.origin + "/ws-message";
  
  
    const url = `/allUsers`; 
  	useEffect(() => {
    
      fetch(url)
        .then((response) => response.json())
        .then((data) => setEmployees(data))
        .catch(error => console.error(error));            
  	}, [url]);
  

  	const [message, setMessage] = useState('Your server message here.');
  	const [alertText, setAlertText] = useState('');
  	let connected = false;
  	const onConnected = () => {
    	console.log("Connected!!");
    	connected = true;
  	}

  	const onMessageReceived = (msg) => {
    	setMessage(msg.message);
  	}
  	
  	
  	const handleEmployees = () => {
	  	sendEmployees(JSON.stringify([checkedEmployee]));
	};
	
	

	const sendEmployees = (message) => {
		const requestOptions = {
    		method: 'POST',
        	headers: { 'Content-Type': 'application/json' },
        	body: JSON.stringify({ message: message})
    	};
    	fetch('/sendEmployees', requestOptions)
        	.then(response => response.json())
        	.then(data => console.log(data));
	}
  	
  	
  	
  	const handleAlert = () => {
	  	sendAlert(alertText)
	};
	

	const sendAlert = (message) => {
		const requestOptions = {
    		method: 'POST',
        	headers: { 'Content-Type': 'application/json' },
        	body: JSON.stringify({ message: message})
    	};
    	fetch('/sendAlert', requestOptions)
        	.then(response => response.json())
        	.then(data => console.log(data));
	}

	const onDisconnect = () => {
		console.log("Disconnected!");
		connected = false;
	}


	const handleRadio = (event) =>  {
		const name = event.target.value;
		setCheckedEmployee(name);
		console.log(checkedEmployee);
  	}	

  const checkBox = (employee,ind) => {
	  const name = employee.name;
	  return <FormControlLabel key={ind}  control={<Radio key={ind} onChange={handleRadio} value={name} checked= {checkedEmployee === name}/>} label={name} />; 
	}

//        	<Header appName="Actor" API = {null} showLocations = {null} showModels = {null} showSpaces = {null}/>
// 			<span>&nbsp;</span>

  return (
	 	<div>

     		<Grid container sx={{ height:'1000px', marginBottom:'30px' }} >
	      			<Grid item sm={12} md={8}>
	      			        <TextField
	          					id="outlined-multiline-static"
	          					label="Alert"
	          					multiline
	          					rows={4}
	          					defaultValue=""
	          					onChange={e => {
	            					setAlertText(e.target.value)
	          					}}
	        				/>
	        				<Button variant="outlined" color="primary" onClick={() => handleAlert()}>Send</Button> 
						   <SockJsClient
						        url={SOCKET_URL}
						        topics={['/topic/message']}
						        onConnect={onConnected}
						        onDisconnect={onDisconnect}
						        onMessage={msg => onMessageReceived(msg)}
						        debug={false}
						      />
	      				 
	          		</Grid>
	          		<Grid item md={4}>
	          			{employees ? (employees.map((employee,ind) => (checkBox(employee,ind)))): null}
	          			<Button variant="outlined" color="primary" onClick={() => handleEmployees()}>Send</Button> 
	          		</Grid>

     		</Grid>
     </div>		
  );
}



export default Manage;
