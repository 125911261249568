import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import App from './App';
import Manage from './Manage';
import Authorize from './Authorize';

function RoutesComponent() {
  return (
	  <BrowserRouter>
    	<Routes>
      		<Route exact path="/" element={<App />} />
      		<Route exact path="/manage" element={<Manage />} />
      		<Route path="/authorize" element={<Authorize />} />
    	</Routes>
    </BrowserRouter>
  );
}

export default RoutesComponent;