import React, { useState, useEffect, useRef} from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary"; 
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'; 
import AccordionDetails from "@mui/material/AccordionDetails"; 
import Typography from "@mui/material/Typography"; 

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import {
	LocationsTable,
	InfoTable,
	ModelTable,
	AnnotationTable,
	DocumentsTable
}  from '../index.js';

function RightAccordion({ 	taskData, updateCheckedLocations, trimbimModels, 
							asyncShowModels, hideModels, colors, selectedLangRoles, 
							annotations,setAlarm, updateCheckedAnnotations, 
							showInfoDialog
						 }) {	
	
//	const [modelsSelected, setModelsSelected] = useState(false);
//	
//		const handleChangeModelShow =  (event, isChecked) =>  {
//			if (isChecked) {
//				asyncShowModels();
//				setModelsSelected(true);
//			} else {
//				hideModels();
//				setModelsSelected(false);
//			}	          
//	  	};

	const [expand, setExpand] = useState([true,false,false,false]) ;

	const [infoData,SetInfoData] = useState([]); 

	
	  const Modelmage = <div><img alt={"Show models"} style = {{display: "inline-block",height: "4em", width: "4em"}} src={window.location.origin + '/images/mat_tool.png'}/>{"Show materials/tools"}</div>;
	  let materialText = "Material and equipment";
	  let locationText = "Work locations";
	  let designText = "Documents";
	  let infoText = "Other";
	

	  if (selectedLangRoles.lang === "fin") {
		  locationText = "Työkohde";
		  materialText = "Materiaalit ja työkalut";
		  designText = "Dokumentit";
		  infoText = "Muu";
	  } else if (selectedLangRoles.lang === "swe") {
		  locationText = "Arbetsobject";
		  materialText = "Material och verktyg";
		  designText = "Dokument";
		  infoText = "Annat";
	  } else if (selectedLangRoles.lang === "est") {
		  locationText = "Töökohad";
		  materialText = "Materjalid ja tööriistad";
		  designText = "Dokumendid";
		  infoText = "Muu";
	  }
	  
	  async function updateInfoTable(guids) {
		 showInfoDialog(guids);
//		 SetInfoData(properties);
//		 setExpand([false,false,true,false])
	  }

	const handleChange = (panel) => {
		let ex = expand;
		ex[panel] = !ex[panel];
		setExpand([...ex]);
	}

	  return (
		<div>
			<Accordion key={'acc-location'} onChange={() =>  handleChange(0)} expanded={expand[0]} style={{ backgroundColor: '#EBEDEF' }} > 
				<AccordionSummary expandIcon={<ArrowDropDownIcon />}> 
					<Typography>{locationText}</Typography> 
				</AccordionSummary> 
				<AccordionDetails> 
					{taskData && taskData.length >0  && <LocationsTable key={taskData.id} tabledata={taskData} colors= {colors} 
																		updateCheckedLocations={updateCheckedLocations} selectedLangRoles={selectedLangRoles} 
																		showProperties={updateInfoTable}/>}
				</AccordionDetails> 
			</Accordion> 
			<Accordion key={'acc-material'} onChange={() => handleChange(1)} expanded={expand[1]}  style={{ backgroundColor: '#EBEDEF' }} > 
				<AccordionSummary expandIcon={<ArrowDropDownIcon />}> 
					<Typography component={'span'}>{materialText}</Typography> 
				</AccordionSummary> 
				<AccordionDetails> 
					<Typography component={'span'}> 
					 	{trimbimModels && <ModelTable trimbimModels={trimbimModels} asyncShowModels={asyncShowModels} selectedLangRoles={selectedLangRoles}/>}
					</Typography> 
		  		</AccordionDetails> 
			</Accordion> 
			<Accordion key={'acc-design'} onChange={() => handleChange(2)} expanded={expand[2]}  style={{ backgroundColor: '#EBEDEF' }}> 
				<AccordionSummary  expandIcon={<ArrowDropDownIcon />}> 
					<Typography>{designText}</Typography> 
				</AccordionSummary> 
				<AccordionDetails> 
					<Typography> 
						{<DocumentsTable selectedLangRoles={selectedLangRoles}/> }
					</Typography> 
				</AccordionDetails> 
			</Accordion> 
			<Accordion key={'acc-other'} onChange={() => handleChange(3)} expanded={expand[3]}  style={{ backgroundColor: '#EBEDEF' }}> 
				<AccordionSummary  expandIcon={<ArrowDropDownIcon />}> 
					<Typography>{infoText}</Typography> 
				</AccordionSummary> 
				<AccordionDetails> 
					<Typography> 
						<Typography component={'span'}> 
					 		{annotations && <AnnotationTable selectedLangRoles={selectedLangRoles} annotations={annotations} setAlarm={setAlarm} updateCheckedAnnotations={updateCheckedAnnotations}/>}
						</Typography> 
					</Typography> 
				</AccordionDetails> 
			</Accordion> 
				
		</div>
	  );
}


export default RightAccordion;