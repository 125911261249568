import React , { useState } from 'react';
import { useTable } from 'react-table';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import CloseIcon from '@mui/icons-material/Close';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import Modal from 'react-modal';
import { Document, Page , pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = 'pdf.worker.js';

Modal.setAppElement('#root'); // This line is needed for accessibility reasons

function Table({ columns, data}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });
 
  return (
	  <div>
	    <table {...getTableProps()}>
	      <tbody {...getTableBodyProps()}>
	        {rows.map(row => {
	          prepareRow(row);
	          return (
	            <tr {...row.getRowProps()}>
	              {row.cells.map(cell => (
	                <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
	              ))}
	            </tr>
	          )
	        })}
	      </tbody>
	    </table>
    </div>
  );
}



function DocumentsTable({ selectedLangRoles }) {
	
  const [isOpen, setIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);	
  const [qrCode, setQrcode] = useState(null);
  const [scale, setScale] = useState(1.0); 
	
  const openModal = (url,qrCode) => {
    	setPdfUrl(url);
    	setQrcode(qrCode);
    	setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  	
  const increaseScale = () => {
    setScale(scale + 0.2);
  };

  const decreaseScale = () => {
    setScale(scale - 0.2);
  };	
	
	
 let name1 = "Floor 1 plan";
  if (selectedLangRoles.lang  === 'fin') {
	  name1 = "Kerros 1 pohja";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name1 = "Floor 1 plan"
  } else if (selectedLangRoles.lang  === 'est') {
	  name1 = "Floor 1 plan";
  }

 let name2 = "Floor 2 plan";
  if (selectedLangRoles.lang  === 'fin') {
	  name2 = "Kerros 2 pohja";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name2 = "Floor 2 plan"
  } else if (selectedLangRoles.lang  === 'est') {
	  name2 = "Floor 2 plan";
  }	
  
 let name3 = "Floor 3 plan";
  if (selectedLangRoles.lang  === 'fin') {
	  name3 = "Kerros 3 pohja";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name3 = "Floor 3 plan"
  } else if (selectedLangRoles.lang  === 'est') {
	  name3 = "Floor 3 plan";
  }  
   let name4 = "Floor 4 plan";
  if (selectedLangRoles.lang  === 'fin') {
	  name4 = "Kerros 4 pohja";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name4 = "Floor 4 plan"
  } else if (selectedLangRoles.lang  === 'est') {
	  name4 = "Floor 4 plan";
  } 
     let name5 = "Floor 5 plan";
  if (selectedLangRoles.lang  === 'fin') {
	  name5 = "Kerros 5 pohja";
  }  else if (selectedLangRoles.lang  === 'swe') {
	  name5 = "Floor 5 plan"
  } else if (selectedLangRoles.lang  === 'est') {
	  name5 = "Floor 5 plan";
  }
  
  
  
const theme = useTheme();	
	
  const columns = React.useMemo(
    () => [
      {
        accessor: 'avatarUrl',
        Cell: ({ cell }) => <Avatar  sx={{ bgcolor: theme.palette.primary.main }} onClick={() => openModal(cell.row.original.link,cell.row.original.qrCode)} style={{cursor: 'pointer'}}><ArchitectureIcon sx={{
          color: "primary.contrastText",
          backgroundColor: "primary.main"
        }}/></Avatar>
      },
      {
        accessor: 'link',
        Cell: ({ cell }) => <button style={{background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer'}} onClick={() => openModal(cell.value,cell.row.original.qrCode)}>{cell.row.original.name}</button>
      },
      // ...other columns
    ],
    [[selectedLangRoles.lang]]
  );

  const data = [
      {
        avatarUrl: 'images/file.png',
        link: '105-017003-01Pohja1kerros.pdf',
        name: name1,
        qrCode: 'qrcode_actor1.png',
      },
      {
        avatarUrl: 'images/file.png',
        link: '105-017003-02Pohja2kerros.pdf',
        name: name2,
        qrCode: 'qrcode_actor2.png',
      },
      {
        avatarUrl: 'images/file.png',
        link: '105-017003-03Pohja3kerros.pdf',
        name: name3,
        qrCode: 'qrcode_actor3.png',
      },
      {
        avatarUrl: 'images/file.png',
        link: '105-017003-04Pohja4kerros.pdf',
        name: name4,
        qrCode: 'qrcode_actor4.png',
        // ...other fields
      },
      {
        avatarUrl: 'images/file.png',
        link: '105-017003-05Pohja5kerros.pdf',
        name: name5,
        qrCode: 'qrcode_actor5.png',
      },
    ];


  return <div>
  		<Table columns={columns} data={data} />
  		<Modal isOpen={isOpen} onRequestClose={closeModal}>
  		<div style={{ display: 'flex', justifyContent: 'space-between' }}>
  			<div>
	  			<IconButton onClick={closeModal}><CloseIcon style={{ fontSize: 40 }} sx={{ border: '1px solid black' }}/></IconButton>
	        	<IconButton onClick={increaseScale} style={{ fontSize: 40 }} sx={{ border: '1px solid black' }}><ZoomInIcon /></IconButton> 
	        	<IconButton onClick={decreaseScale} style={{ fontSize: 40 }} sx={{ border: '1px solid black' }}><ZoomOutIcon /></IconButton> 	
			</div>
	        <img src={qrCode} style={{ height: '100px'}} />
		</div> 
        <div style={{ border: '2px solid black' }}>
        	<Document file={pdfUrl}>
          		<Page pageNumber={1} scale={scale}/>
        	</Document>
        	</div>
      	</Modal>
  	</div>
}

export default DocumentsTable;