import React, { useState, useEffect, useRef } from "react";
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Flags from 'country-flag-icons/react/3x2';
import Typography from "@mui/material/Typography"; 
import { Select, MenuItem } from '@mui/material';

function Language(props) {
	  const { selectedLangRoles, setSelectedLangRoles,availableLangRoles,showLocations } = props;	  
	  			  				  		
	  const handleChangeLang = (event) =>  {
			const lang = event.target.value;   
			showLocations(selectedLangRoles.roles,lang);
			setSelectedLangRoles({lang: lang, roles : selectedLangRoles.roles});	          
	  	};
		
		
	 const MenuSelection = (lang,ind) => {
		  	let FlagComponent = null;
		  	if (lang === "fin" || lang === "FIN") {
			  	FlagComponent = <Flags.FI title="Finnish" style={{ display: "inline-block", height: "2.5em" }}/>
		  	} else if (lang === "swe" || lang === "SWE") {
			  	FlagComponent = <Flags.SE title="Swedish" style={{ display: "inline-block", height: "2.5em"}}/>
		  	} else if (lang === "est" || lang === "EST") {
			  	FlagComponent = <Flags.EE title="Estonian" style={{ display: "inline-block", height: "2.5em"}}/>
		  	}
		  	else {
				// flag = Flags["GB"];
			 	FlagComponent = <Flags.GB title="English" style={{ display: "inline-block", height: "2.5em"}}/>
		  	} 
		  	return <MenuItem key={"lang" + ind} value={lang}>
          				{FlagComponent}
        			</MenuItem>
		}

  return (
			<Select value={selectedLangRoles.lang} onChange={handleChangeLang}>
				{availableLangRoles.langs? (availableLangRoles.langs.map((lang,ind) => (MenuSelection(lang,ind)))): null}
			</Select>
  );
}

export default Language;